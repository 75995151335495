import React from "react";
import { css } from "@emotion/react";
import { BREAKPOINTS, COLORS, FONT_FAMILIES } from "../../css-vars";
import { mediaQuery, pixelsToRem } from '../../utils/css-helpers';

import Heading from "../Heading";
import Paragraph from "../Paragraph";
import SmartImg from "../SmartImg";
import Strapline from "../Strapline";

const styles = {
  0: css`
    color: ${COLORS.dark};
    font-family: ${FONT_FAMILIES.body};
    line-height: 1.9em;
    margin: 0;
    max-width: 100%;
    padding: 0;
  `,
  a: css`
    color: inherit;
    cursor: pointer;
    text-decoration: none;
  `,
  btnWrap: css`
    align-items: center;
    bottom: ${pixelsToRem(16)};
    color: ${COLORS.secondaryA11y};
    display: flex;
    gap: ${pixelsToRem(4)};
    justify-content: center;
    position: absolute;
  `,
  figcaption: css`
    padding: ${pixelsToRem(16)} ${pixelsToRem(25)} ${pixelsToRem((2*16)+25)};

    ${mediaQuery({minWidth: BREAKPOINTS.medium})} {
      min-height: ${pixelsToRem(269)};
    }
  `,
  figure: css`
    background-color: #fff;
    margin: 0;
    min-height: 100%;
    position: relative;
    transition: transform .2s ease-in-out;
    will-change: transform;
  `,
  gt: css`
    font-size: ${pixelsToRem(16)};
  `,

  Heading: css`
    margin-top: 0;
  `,
  Paragraph: css``,
  SmartImg: css`
    margin: 0;
  `,
  Strapline: css`
    text-transform: none;
  `,
};

const FeatureBoxItem = ({
  altText = "",
  body,
  ctaLink = null,
  ctaText = null,
  group,
  heading,
  photo,
  photoFluid = null,
  ...props
}) => {

  const figure = () => (
    <figure css={styles.figure}>
      <SmartImg
        alt={altText}
        css={styles.SmartImg}
        fluid={photoFluid || photo?.gatsbyImage || photo}
      />
      
      <figcaption css={styles.figcaption}>
        {group && <Strapline css={styles.Strapline}>{group}</Strapline>}
        {heading && <Heading css={styles.Heading} tag={"h4"}>{heading}</Heading>}
        {body && <Paragraph css={styles.Paragraph} level={"small"}>{body}</Paragraph>}
      </figcaption>
      
      {ctaText &&
        <div className="fbi__btn-wrap" css={styles.btnWrap}>
          <div>
            <span>{ctaText}</span>
          </div>
          <div css={styles.gt}>
            <span>&gt;</span>
          </div>
        </div>
      }
    </figure>
  )

  return (
    <li className="fbi__root glide__slide slider" {...props} css={styles[0]}>
      {ctaLink ?
        <a css={styles.a} href={ctaLink}>{figure()}</a>
      :
        <div>{figure()}</div>
      }
    </li>
  )
};

export default FeatureBoxItem;
