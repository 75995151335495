import React from "react";
import { css } from "@emotion/react";
import { BREAKPOINTS } from "../../css-vars";
import { mediaQuery, pixelsToRem } from "../../utils/css-helpers";

const styles = {
  0: css`
    margin: ${pixelsToRem(25)} 0;
    width: 100%;

    ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
      margin-bottom: ${pixelsToRem(50)};
      margin-top: ${pixelsToRem(50)};
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      margin-bottom: ${pixelsToRem(100)};
      margin-top: ${pixelsToRem(100)};
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
      margin-bottom: ${pixelsToRem(140)};
      margin-top: ${pixelsToRem(140)};
    }

  // Feature Box Item
    .fbi__btn-wrap {
      right: ${pixelsToRem(24)};
    }
    .fbi__root {
      flex: 1 0 100%;

      ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
        flex: 0 1 calc(50% - ${pixelsToRem(25)});
      }

      ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
        flex: 0 1 calc(50% - ${pixelsToRem(25+25)});
      }
    }
    a {
      &:hover {
        figure {
          transform: translateY(${pixelsToRem(-8)});
        }
      }
    }
    figure {
      border-radius: ${pixelsToRem(8)};
      filter: drop-shadow(0 ${pixelsToRem(2)} ${pixelsToRem(8)} rgba(0,0,0,.25));
      max-width: ${pixelsToRem(406)};
      overflow: hidden;
    }
  `,
  
  ul: css`
    align-items: center;
    display: flex;
    flex-flow: column;
    gap: ${pixelsToRem(48)};
    justify-content: center;
    list-style-type: none;
    margin: 0 auto;
    max-width: ${pixelsToRem(BREAKPOINTS.medium)};
    padding: 0 ${pixelsToRem(25)};

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      align-items: stretch;
      flex-flow: row wrap;
      padding-left: ${pixelsToRem(50)};
      padding-right: ${pixelsToRem(50)};
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
      gap: ${pixelsToRem(48+50)};
      padding-left: ${pixelsToRem(25)};
      padding-right: ${pixelsToRem(25)};
    }
  `,
};

const FeatureBoxGrid = ({
  children,
}) => {
  return (
    <div css={styles[0]} className="fbg__root">
      <ul css={styles.ul}>
        {children}
      </ul>
    </div>
  )
};

export default FeatureBoxGrid;
