import React from "react";
import FaqList, {FaqItem} from "./FAQ";

const ContentfulFaqList = ({
  contentfulData: {
    heading,
    items
  },
  ...props
}) => (
  <FaqList heading={heading} {...props}>
    {items?.length && items.map(({ id, ...itemProps }) =>
      <FaqItem key={id} {...itemProps} />
    )}
  </FaqList>
);

export default ContentfulFaqList;
