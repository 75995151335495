import React from 'react'
import { css } from "@emotion/react";
import ResponsiveContainer from "./ResponsiveContainer";
import Heading from "./Heading";
import Card from "./Card";

import { mediaQuery, pixelsToRem, createSpacingUnitMediaQueries } from "../utils/css-helpers";
import { BREAKPOINTS } from "../css-vars";

const CopyWithCards = ({
  heading,
  strapline,
  copy,
  cards = [],
}) => (

  <section>
    <ResponsiveContainer width="thin" spacingBottom="none">
      {heading && <Heading strapline={strapline}>{heading}</Heading>}
      {copy}
    </ResponsiveContainer>

    <ResponsiveContainer spacingTop="half" css={css`
      ${mediaQuery({maxWidth: BREAKPOINTS.small})} {
        max-width: ${pixelsToRem(480)};
      }
      ${mediaQuery({minWidth: BREAKPOINTS.small})} {
        display: -ms-grid;
        -ms-grid-columns: 1fr ${pixelsToRem(20)} 1fr ${pixelsToRem(20)} 1fr;

        display: grid;
        grid-template-columns: 1fr ${pixelsToRem(20)} 1fr ${pixelsToRem(20)} 1fr;

        justify-content: space-between;
      }
    `}>
      {cards.map((card, cardIndex) => {
        return <Card key={cardIndex} {...card} css={css`
          margin-bottom: ${pixelsToRem(20)};

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }

          ${createSpacingUnitMediaQueries({
            'margin': `calc(SPACING_UNIT / 2) 0`,
          }, {
            excludeBreakpoints: [`tiny`, `small`],
          })};

          &:first-of-type {
            ${createSpacingUnitMediaQueries({
              'margin-bottom': `SPACING_UNIT`,
            }, {
              excludeBreakpoints: [`tiny`, `small`],
              includeMin: {
                medium: `margin-top: 0;`,
              },
            })}
          }

          &:last-of-type {
            ${createSpacingUnitMediaQueries({
              'margin-top': `SPACING_UNIT`,
            }, {
              excludeBreakpoints: [`tiny`, `small`],
              includeMin: {
                medium: `margin-bottom: 0;`,
              },
            })}
          }

          ${mediaQuery({minWidth: BREAKPOINTS.small})} {
            -ms-grid-column: ${cardIndex * 2 + 1};
            -ms-grid-row: 1;

            grid-column: ${cardIndex * 2 + 1};
            grid-row: 1;

            max-width: none;
            width: 100%;
          }
        `} />
      })}
    </ResponsiveContainer>
  </section>
);

export default CopyWithCards;
