import React from "react"
import { css } from "@emotion/react"
import Heading from "./Heading"
import CallToAction from "./CallToAction"
import ResponsiveContainer from "./ResponsiveContainer"
import TwoColumns from "./TwoColumns"
import PhotoWithFlourish from "./PhotoWithFlourish"
import { BREAKPOINTS } from "../css-vars"
import { mediaQuery } from "../utils/css-helpers"
import { BLOCKS } from "@contentful/rich-text-types"

const CopyWithPhoto = ({
  strapline,
  title,
  copy,
  cta,
  ctaHref,
  photoPosition = `left`,
  photoFluid,
  overlayFluid,
  flourishColor,
  altText,
  fullWidth,
  video,
  videoThumbnail,
  newsletterSignup,
  ...props
}) => {
  const textPosition = photoPosition === `left` ? `right` : `left`

  const copyElem = (
    <>
      {title && (
        <Heading spacingTop="half" strapline={strapline}>
          {title}
        </Heading>
      )}
      {!!copy && copy}
      {cta && ctaHref && (
        <CallToAction
          anchorClassName="pageblockcta" // Class to make the link identifiable in Google Tag Manager
          spacingBottom="none"
          link={ctaHref}
          caption={cta}
        />
      )}
    </>
  )
  const photoElem = newsletterSignup ? (
    <div>
      <h1>hello world</h1>
    </div>
  ) : video ? (
    <video
      width="100%"
      height="auto"
      controls
      controlsList="nodownload"
      poster={videoThumbnail}
      preload="none"
    >
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  ) : (
    <PhotoWithFlourish
      css={css`
        // WHY: Centers the component without adding a div wrapper
        margin: 0;
        left: 50%;
        transform: translateX(-50%);

        // WHY: Taken from PhotoWithFlourish to reverse the containment – the component contains all of the flourish
        //      and card aspects within the div, but in this instance we want the image itself to line up with the parent
        //      so we use a reversed percentage calculation based on the width of the image and the card overlay
        width: ${(650 / 500) * 100}%;

        ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
          // WHY: When unstacked, we want to bring the image back up the page by the invisible margin created by the card
          //      which is equal to the percentage width of the gutter from the original image size
          margin-top: -${(75 / 500) * 100}%;
          margin-bottom: -${(75 / 500) * 100}%;
        }
      `}
      photoFluid={photoFluid}
      overlayFluid={overlayFluid}
      flourishPosition={photoPosition}
      flourishColor={flourishColor}
      title={title}
      altText={altText}
    />
  )

  ////////////////////////////
  // Full Width Photo Scenario
  ////////////////////////////

  const fwjsx = () => {
    return (
      <ResponsiveContainer>
        <h1
          css={css`
            font-family: circular,sans-serif;
            line-height: 1.15;
            font-weight: 500;
            font-size: 3rem;
            text-align: center;
          `}
        >
          {title}
        </h1>
        <div style={{ width: "40%", margin: "auto" }}>{copy}</div>
        {video ? (
          <video
            width="100%"
            height="auto"
            controls
            controlsList="nodownload"
            poster={videoThumbnail}
            preload="none"
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img style={{ display: "block", margin: "auto", width: "90%" }} src={photoFluid.src} />
        )}
      </ResponsiveContainer>
    )
  }

  return fullWidth ? (
    fwjsx()
  ) : (
    <section {...props}>
      <ResponsiveContainer>
        <TwoColumns
          left={photoPosition === `left` ? photoElem : copyElem}
          right={photoPosition === `right` ? photoElem : copyElem}
          topColumnOnMobile={textPosition}
        />
      </ResponsiveContainer>
    </section>
  )
}

export default CopyWithPhoto
