import React from "react";
import { css } from "@emotion/react";
import { BREAKPOINTS, COLORS } from "../../css-vars";
import { mediaQuery, pixelsToRem, responsiveFontSizes } from "../../utils/css-helpers";

const styles = {
  // - Root
  0: css`
    margin: ${pixelsToRem(25)} 0;

    ${mediaQuery({minWidth: BREAKPOINTS.small})} {
      margin-bottom: ${pixelsToRem(50)};
      margin-top: ${pixelsToRem(50)};
    }

    ${mediaQuery({minWidth: BREAKPOINTS.medium})} {
      margin-bottom: ${pixelsToRem(100)};
      margin-top: ${pixelsToRem(100)};
    }

    ${mediaQuery({minWidth: BREAKPOINTS.large})} {
      margin-bottom: ${pixelsToRem(140)};
      margin-top: ${pixelsToRem(140)};
    }
  `,

  // - List
  child: css`
    list-style-type: none;
    margin: 0 auto;
    max-width: ${pixelsToRem(810)};
    padding: 0 ${pixelsToRem(25)};
  `,

  // - H2
  h2: css`
    ${responsiveFontSizes(26, 48)}

    color: ${COLORS.dark};
    margin: ${pixelsToRem(25)} 0;
    text-align: center;

    &.empty {
      margin: 0;
    }

    ${mediaQuery({minWidth: BREAKPOINTS.medium})} {
      margin-bottom: ${pixelsToRem(50)};
      margin-top: ${pixelsToRem(50)};
    }
  `,
};

const FaqList = ({
  children,
  heading = '',
}) => {
  return (
    <div css={styles[0]} className="faq__root">
      <header css={styles.child}>
        <h2 className={`_heading-override ${(heading && heading.length > 1) ? '' : 'empty'}`} css={styles.h2}>{heading}</h2>
      </header>

      <ul css={styles.child}>
        {children}
      </ul>
    </div>
  )
};

export default FaqList;
