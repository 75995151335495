import React from "react";
import { css } from "@emotion/react";
import Copy from "./Copy";
import ResponsiveContainer from "./ResponsiveContainer";
import ContentfulRichText from "./ContentfulRichText";

const ContentfulCopy = ({
  contentfulData: {
    alignment,
    headingStrapline,
    heading,
    bodyContent = {},
    ctaLabel,
    ctaLink,
  },
  children,
  ...props
}) => {

  //const { raw } = bodyContent;
  const align = (alignment || `left`).toLowerCase();

  return (
    <section {...props}>
      <ResponsiveContainer width={"thin"}>
        <Copy
          strapline={headingStrapline}
          heading={heading}
          align={align}
          ctaLabel={ctaLabel}
          ctaLink={ctaLink}
          copy={bodyContent &&
            <ContentfulRichText
              json={bodyContent}
              css={css`
                text-align: ${align};
                margin-left: ${align === `left` ? 0 : `auto`};
                margin-right: ${align === `right` ? 0 : `auto`};
              `}
            />
          }
        >
          {children}
        </Copy>
      </ResponsiveContainer>
    </section>
  );
};

export default ContentfulCopy;
