import React from "react";
import { css } from "@emotion/react";
import { BREAKPOINTS, COLORS } from "../../css-vars";
import { mediaQuery, pixelsToRem } from "../../utils/css-helpers";
import ContentfulRichText from "../ContentfulRichText";

const styles = {
  // - Item root
  0: css`
    ul {
      list-style-type: none;
      margin: 0;
      padding-bottom: ${pixelsToRem(16)};

      li {
        font-weight: inherit;
        margin: ${pixelsToRem(8)} 0 0;

        &::before {
          display: none;
        }
      }
    }
  `,

  // - Button arrow
  arrow: css`
    margin-left: ${pixelsToRem(32)};
    transition: .25s ease-in-out margin-left;
  `,

  // - Button
  btn: css`
    align-items: center;
    appearance: none;
    background-color: ${COLORS.secondaryA11y};
    border-radius: 0;
    border: 0;
    color: ${COLORS.lightest};
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 0;
    min-width: ${pixelsToRem(40)};
    padding: ${pixelsToRem(14)} ${pixelsToRem(20)};
    text-decoration: none;
    transition: .25s ease-in-out background-color;

    &:hover {
      background-color: ${COLORS.secondaryA11yDark};

      .faq__arrow {
        margin-left: ${pixelsToRem(40)};
      }
    }
  `,

  // - Button wrap
  btnWrap: css`
    display: inline-block;
    margin-top: ${pixelsToRem(32)};
  `,

  // - Caret
  caret: css`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: ${pixelsToRem(12)};
    transform-origin: center;
    transition: .25s ease-in-out transform;
  `,

  // - Question open/close
  details: css`
    &[open] {
      summary {
        background-color: #f5f5f5;
        color: ${COLORS.primaryA11y};
      }
      + section {
        border-bottom: ${pixelsToRem(1)} solid rgba(0, 72, 232, .3);
      }
    }

    &:not([open]) {
      .faq__caret {
        transform: rotate(-180deg);
      }
      + section {
        height: 0;
        opacity: 0;
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  `,

  // - Answer wrap
  section: css`
    overflow: hidden;
    padding: ${pixelsToRem(16)} 6.25% ${pixelsToRem(54)};
    transition: .4s ease-in-out all;
    will-change: height;

    ${mediaQuery({minWidth: BREAKPOINTS.small})} {
      padding-left: ${pixelsToRem(32)};
      padding-right: ${pixelsToRem(32)};
    }
  `,

  // - Question wrap
  summary: css`
    align-items: center;
    border-bottom: ${pixelsToRem(1)} solid rgba(0, 72, 232, .3);
    cursor: pointer;
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    list-style: none;
    padding: ${pixelsToRem(14)} 6.25%;
    transition: .25s ease-in-out;

    &::before, &::marker, &::-webkit-details-marker {
      display: none;
    }

    ${mediaQuery({minWidth: BREAKPOINTS.small})} {
      font-size: ${pixelsToRem(19)};
      padding-left: ${pixelsToRem(32)};
      padding-right: ${pixelsToRem(32)};
    }
  `,
};

const FaqItem = ({
  answer,
  ctaLink = null,
  ctaText = null,
  question,
}) => {
  return (
    <li css={styles[0]} className="faq__item">
      <details css={styles.details}>
        <summary css={styles.summary} className="faq__question-wrap">
          <div className="faq__question"><span>{question}</span></div>

          <div css={styles.caret} className="faq__caret"><svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5698 6L9.28347 1L1.99711 6" stroke="#0048E8" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/></svg></div>
        </summary>
      </details>

      <section css={styles.section} className="faq__answer-wrap">
        <div className="faq__answer">
          {typeof answer === 'string' ?
            <p css={css`white-space:pre-wrap;`}>{answer}</p>
          :
            <ContentfulRichText json={answer} />
          }
        </div>

        {ctaLink && ctaText &&
          <div css={styles.btnWrap} className="faq__btn-wrap">
            <a css={styles.btn} href={ctaLink}>
              <div>
                <span>{ctaText}</span>
              </div>

              <div css={styles.arrow} className="faq__arrow">
                <span><svg viewBox="0 0 29 18" xmlns="http://www.w3.org/2000/svg" width="20"><path d="M1 9h26m-6.5 5.5 7-5.5-7-5.5" stroke="#FFF" strokeWidth="1.5" fill="none" fillRule="evenodd"></path></svg></span>
              </div>
            </a>
          </div>
        }
      </section>
    </li>
  )
};

export default FaqItem;
