import React from "react";
import {css} from "@emotion/react";

import { BREAKPOINT_SPACING } from "../css-vars";
import { pixelsToRem } from "../utils/css-helpers";
import Heading from "./Heading";
import SmartImg from "./SmartImg";

const TeamMember = ({
  photoFluid,
  name,
  jobTitle,
  layout,
  shortBio,
  ...props
}) => {
  const isLayoutShort = layout === 'Short';
  return (
    <figure {...props} css={isLayoutShort && css`
      display: flex;
      flex-flow: row nowrap;
    `}>
      <SmartImg fluid={photoFluid} css={isLayoutShort && css`
        flex: 3;
        max-width: ${pixelsToRem(BREAKPOINT_SPACING.large)};
        max-height: ${pixelsToRem(BREAKPOINT_SPACING.large)};
        
      `}/>
      <div css={isLayoutShort && css`
        flex: 2;
        padding-left: ${pixelsToRem(BREAKPOINT_SPACING.small / 4)};
      `}>
        <Heading tag="h3" level="h4" spacingTop="half" spacingBottom="none">
          {name}
        </Heading>
        <Heading spacingTop="half" level="h5" tag="p">
          {jobTitle}
        </Heading>
      </div>
      {!isLayoutShort ? shortBio : ''}
    </figure>
  );
};

export default TeamMember;
