import React from "react"
import { css } from "@emotion/react"
import Heading from "./Heading"
import ResponsiveContainer from "./ResponsiveContainer"
import PhotoWithFlourish from "./PhotoWithFlourish"
import { BREAKPOINTS } from "../css-vars"
import { mediaQuery } from "../utils/css-helpers"

const FullWidthPhoto = ({
  title,
  copy,
  photoFluid,
  overlayFluid,
  flourishColor,
  altText,
  ...props
}) => {
  const copyElem = (
    <>
      {title && <Heading align="center">{title}</Heading>}
      <div
        css={css`
          width: 40%;
          margin: auto;
        `}
      >
        {!!copy && copy}
      </div>
    </>
  )
  const photoElem = (
    <PhotoWithFlourish
      css={css`
        // WHY: Centers the component without adding a div wrapper
        margin: 0;
        left: 50%;
        transform: translateX(-50%);

        // WHY: Taken from PhotoWithFlourish to reverse the containment – the component contains all of the flourish
        //      and card aspects within the div, but in this instance we want the image itself to line up with the parent
        //      so we use a reversed percentage calculation based on the width of the image and the card overlay
        width: ${(650 / 500) * 100}%;

        ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
          // WHY: When unstacked, we want to bring the image back up the page by the invisible margin created by the card
          //      which is equal to the percentage width of the gutter from the original image size
          margin-top: -${(75 / 500) * 100}%;
          margin-bottom: -${(75 / 500) * 100}%;
        }
      `}
      photoFluid={photoFluid}
      overlayFluid={overlayFluid}
      flourishColor={flourishColor}
      title={title}
      altText={altText}
      flourishPosition="right"
    />
  )

  return (
    <section {...props}>
      <ResponsiveContainer>
        {copyElem}
        {photoElem}
      </ResponsiveContainer>
    </section>
  )
}

export default FullWidthPhoto
