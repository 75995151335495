import React from "react";
import CopyWithCards from "./CopyWithCards";
import ContentfulRichText from "./ContentfulRichText";

const ContentfulCopyWithCards = ({
  contentfulData: {
    heading,
    headingStrapline,
    bodyContent = {},
    ...contentfulData
  },
  ...props
}) => {

  //const { raw } = bodyContent;

  // WHY: Contentful returns a flat object of data with the keys title1, content1, title2, content2,
  // etc so we need to loop through the number of cards and create an object for each
  const cards = [];
  for (let i = 1; i <= 3; ++i) {
    const {
      [`cardTitle${i}`]: title,
      [`cardContent${i}`]: content,
      [`cardIcon${i}`]: iconId,
    } = contentfulData;

    cards.push({
      title,
      content,
      iconId,
    })
  }

  return (
    <CopyWithCards
      {...props}
      strapline={headingStrapline}
      heading={heading}
      copy={bodyContent && <ContentfulRichText json={bodyContent} />}
      cards={cards}
    />
  );
};

export default ContentfulCopyWithCards;
