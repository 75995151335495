import React from "react";
import { css } from "@emotion/react";

import { mediaQuery, pixelsToRem, createSpacingUnitMediaQueries, createAutoPopulatedGrid } from "../utils/css-helpers";
import { BREAKPOINTS, BREAKPOINT_SPACING } from "../css-vars";

import ResponsiveContainer from "./ResponsiveContainer";
import Heading from "./Heading";

const getLayoutCss = (isLayoutShort, children) => {
  if (isLayoutShort) {
    return `
      ${mediaQuery({
        minWidth: BREAKPOINTS.small,
        maxWidth: BREAKPOINTS.large
      })} {
        ${createAutoPopulatedGrid(children.length, {
          columns: 2,
          columnGap: `7%`,
          rowGap: pixelsToRem(BREAKPOINT_SPACING.small / 2),
        })};
      }

      ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
        ${createAutoPopulatedGrid(children.length, {
          columns: 3,
          columnGap: `7%`,
          rowGap: pixelsToRem(BREAKPOINT_SPACING.medium / 2),
        })};
      }

      ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
        > :nth-of-type(n) {
          margin-bottom: ${pixelsToRem(BREAKPOINT_SPACING.tiny / 2)};
        }
      }
    `;
  }
  return `
    ${mediaQuery({
      minWidth: BREAKPOINTS.tiny,
      maxWidth: BREAKPOINTS.medium
    })} {
      ${createAutoPopulatedGrid(children.length, {
        columns: 2,
        columnGap: `7%`,
        rowGap: pixelsToRem(BREAKPOINT_SPACING.small / 2),
      })};
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      ${createAutoPopulatedGrid(children.length, {
        columns: 3,
        columnGap: `7%`,
        rowGap: pixelsToRem(BREAKPOINT_SPACING.medium / 2),
      })};
    }

    ${mediaQuery({ maxWidth: BREAKPOINTS.tiny })} {
      > :nth-of-type(n) {
        margin-bottom: ${pixelsToRem(BREAKPOINT_SPACING.tiny / 2)};
      }
    }
    `;
};

const Team = ({
  headingStrapline = `Leadership`,
  heading = `Meet the Team`,
  memberLayout,  
  children,
  ...props
}) => (
  <section {...props}>
    <ResponsiveContainer>
      <Heading
        align="right"
        strapline={headingStrapline}
      >
        {heading}
      </Heading>
      <div css={css`
        ${createSpacingUnitMediaQueries({
          'margin-top': 'calc(SPACING_UNIT / 2)',
        })}

        ${getLayoutCss(memberLayout === 'Short', children)}
      `}>
        {children}
      </div>
    </ResponsiveContainer>
  </section>
);

export default Team;
