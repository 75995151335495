import React, { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { BREAKPOINTS, COLORS } from "../css-vars";
import { mediaQuery, pixelsToRem } from "../utils/css-helpers";
import Heading from "./Heading";
import Paragraph from "./Paragraph";


const styles = {
  // - Root
  0: css`
    margin: ${pixelsToRem(25)} auto;
    max-width: ${pixelsToRem(1200)};
    width: 100%;

    ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
      margin-bottom: ${pixelsToRem(50)};
      margin-top: ${pixelsToRem(50)};
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;
      margin-bottom: ${pixelsToRem(100)};
      margin-top: ${pixelsToRem(100)};
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
      margin-bottom: ${pixelsToRem(140)};
      margin-top: ${pixelsToRem(140)};
    }


    // - Marketo Form
    .mktoForm,
    .mktoForm .mktoLabel,
    .mktoForm .mktoCheckboxList,
    .mktoForm input[type=email] {
      padding: 0;
      width: 100% !important;
    }
    .mktoForm .mktoCheckboxList {
      padding-left: ${pixelsToRem(12)};
      padding-top: ${pixelsToRem(8)};
    }
    .mktoForm .mktoFormCol,
    .mktoForm .mktoFieldWrap {
      width: 100%;
    }
    .mktoForm .mktoFieldWrap {
      align-items: flex-start;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
    }
    .mktoForm .mktoLabel,
    .mktoForm .mktoFormRow .mktoField,
    .mktoForm button.mktoButton {
      font-size: ${pixelsToRem(16)};
    }
    .mktoForm .mktoError .mktoErrorMsg,
    .mktoForm .mktoError .mktoErrorArrow {
      background-color: #104f92;
      border-color: #104f92;
    }
    .mktoForm .mktoError .mktoErrorMsg {
      background-image: -webkit-linear-gradient(#104f92 43%, #005aa1 100%);
      background-image: -moz-linear-gradient(#104f92 43%, #005aa1 100%);
      background-image: linear-gradient(#104f92 43%, #005aa1 100%);
      background-image: -ms-linear-gradient(#104f92 43%, #005aa1 100%);
      -webkit-box-shadow: rgb(0 0 0 / 65%) 0 2px 7px, inset #005aa1 0 1px 0px;
      box-shadow: rgb(0 0 0 / 65%) 0 2px 7px, inset #005aa1 0 1px 0px;
    }
    .mktoButtonRow ~ *:not([type=hidden]),
    .mktoForm .mktoRequiredField .mktoAsterix,
    .mktoForm .mktoGutter,
    .mktoFormRow:has(.mktoHtmlText) {
      display: none;
    }
    .mktoForm label {
      color: ${COLORS.dark};
      margin: 0 0 ${pixelsToRem(6)};
      
      &.mktoLabel::after {
        color: ${COLORS.primaryA11y};
        content: "*";
        margin-left: ${pixelsToRem(4)};
      }
    }
    .mktoForm .mktoFormRow .mktoField {
      background: #ffffff;
      border: 1px solid #cecece;
      border-radius: ${pixelsToRem(3)};
      padding: ${pixelsToRem(12)} ${pixelsToRem(18)};

      &::placeholder {
        color: rgba(0, 30, 96, 0.47);
      }
    }
    .mktoButtonRow {
      margin-top: ${pixelsToRem(40)};
    }
    .mktoButtonWrap {
      margin-left: 0 !important;
    }
    .mktoForm input[type=email] {
      margin-bottom: ${pixelsToRem(26)};
      max-width: ${pixelsToRem(390)};
    }
    .mktoForm .mktoField[type=checkbox] {
      visibility: hidden;

      + label {
        cursor: pointer;
        font-size: ${pixelsToRem(16)};
        max-width: ${pixelsToRem(390)};
        position: relative;

        &::before, &::after {
          content: "";
          display: block;
          left:  0;
          position: absolute;
          top: 50%;
        }
        &::before {
          background: #FFFFFF;
          border-radius: ${pixelsToRem(3)};
          border: ${pixelsToRem(1)} solid #cecece;
          height: ${pixelsToRem(16)};
          transform: translate(${pixelsToRem(-26)}, -50%);
          width: ${pixelsToRem(16)};
        }
        &:not(:first-of-type) {
          margin-top: ${pixelsToRem(16)};
        }
      }
      &:checked {
        + label {
          &::after {
            background-color: ${COLORS.dark};
            border-radius: ${pixelsToRem(1)};
            height: ${pixelsToRem(12)};
            transform: translate(${pixelsToRem(-23)}, -50%);
            width: ${pixelsToRem(12)};
          }
        }
      }
    }
    .mktoForm button.mktoButton {
      background-color: ${COLORS.secondaryA11y};
      border: 0;
      color: ${COLORS.lightest};
      padding: ${pixelsToRem(20)} ${pixelsToRem(24)};
      position: relative;

      background-color: transparent;
      background-repeat: no-repeat!important;
      background-image: linear-gradient(#018347, #018347)!important;
      background-size: calc(100% - 0.667em) 100%!important;
      border: 0!important;
      box-shadow: none!important;
      color: #FFFFFF!important;
      padding: 1.2em 1.5em!important;
      padding-right: calc(1.5em + 0.667em)!important;
      position: relative;
      transition: background 250ms ease-in-out,background-color 250ms ease-in-out;

      &::after {
        background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjkgMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjEuNSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxwYXRoIGQ9Ik0xIDloMjZNMjAuNSAxNC41bDctNS41LTctNS41Ii8+CiAgPC9nPgo8L3N2Zz4K) center no-repeat;
        content: '';
        display: inline-block;
        margin-left: 2em;
        width: 1.611em;
        height: 1em;
        transition: transform 250ms ease-in-out;
        vertical-align: top;
      }
      &:hover {
        background-image: linear-gradient(#004e2a, #004e2a)!important;
        background-size: 100% 100%!important;

        &::after {
          transform: translateX(calc(0.667em / 2));
        }
      }
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      .mktoForm .mktoFormRow .mktoRequiredField .mktoAsterix {
        margin-left: ${pixelsToRem(4)};
        order: 2;
      }
      label {
        align-items: center;
        display: flex;
        justify-content: flex-start;
      }
    }
  `,

  Heading: css`
    margin: 0 0 ${pixelsToRem(14)};

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      margin-bottom: ${pixelsToRem(28)};
    }
  `,

  Paragraph: css`
    margin: 0;
  `,

  section: css`
    flex: 1 1 50%;
    margin: 0 0 ${pixelsToRem(40)};
    padding: 0 ${pixelsToRem(25)};

    ${mediaQuery({minWidth: BREAKPOINTS.small})} {
      padding-left: ${pixelsToRem(50)};
      padding-right: ${pixelsToRem(50)};
    }
  `,
};


const NewsletterSignup = ({
  heading = 'Join our Newsletter',
  body = '',
}) => {
  const divRef = useRef(null);

  useEffect(() => {
    if(!divRef.current) return;

    const slotHtml = document.createRange().createContextualFragment(`
<script src="//go.svclnk.com/js/forms2/js/forms2.min.js"></script>
<form id="mktoForm_1309"></form>
<script>
  function loadForm(count) {
    setTimeout(function() {
      if(window.MktoForms2) {
        MktoForms2.loadForm("//go.svclnk.com", "991-WDA-741", 1309);
      } else if(count <= 10) {
        loadForm(count++);
      }
    }, 200);
  }
  loadForm(0);
</script>`);
    
    divRef.current.innerHTML = '';
    divRef.current.appendChild(slotHtml);
  }, [divRef]);

  return (
    <div className="ns__root" css={styles[0]}>
      <section css={styles.section}>
        <Heading css={styles.Heading} tag={"h2"}>{heading}</Heading>
        {body && <Paragraph css={styles.Paragraph} level={"small"}>{body}</Paragraph>}
      </section>

      <section css={styles.section}>
        <div ref={divRef}></div>
      </section>
    </div>
  )
}

export default NewsletterSignup;
