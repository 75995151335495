import React from "react";
import { css } from "@emotion/react";

import { createSpacingUnitMediaQueries } from "../utils/css-helpers";

import ResponsiveContainer from "./ResponsiveContainer";
import Copy from "./Copy";

const IconGrid = ({
  strapline,
  heading,
  content,
  children,
  ...props
}) => (
  <section {...props}>
    <ResponsiveContainer>

      <Copy
        strapline={strapline}
        heading={heading}
        copy={content}
        align="center"
      />

      <div css={css`
        ${createSpacingUnitMediaQueries({
          'margin-top': `calc(SPACING_UNIT / 2)`,
        })}

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: auto;
      `}>
        {children}
      </div>

    </ResponsiveContainer>
  </section>
);

export default IconGrid
