import React from "react";
import { css } from "@emotion/react";
import IconGrid from "./IconGrid";
import Icon from "./Icon";
import ContentfulRichText from "./ContentfulRichText";

const ContentfulIconGrid = ({
  contentfulData: {
    headingStrapline,
    heading,
    content,
    icons
  },
  ...props
}) => (
  <IconGrid
    {...props}
    strapline={headingStrapline}
    heading={heading}
    content={content &&
      <ContentfulRichText
        json={content}
        css={css`
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        `}
      />
    }
  >
    {icons.map(({
      id,
      icon: {file},
      heading,
      body,
      altText
    }) =>
      <Icon
        key={id}
        iconUrl={file?.url}
        heading={heading}
        body={body}
        altText={altText}
      />
    )}
  </IconGrid>
);

export default ContentfulIconGrid;
