import React from "react";
import { FeatureBoxCarousel, FeatureBoxItem } from "./FeatureBox";

const ContentfulFeatureBoxCarousel = ({
  contentfulData: {
    items,
    ...rootProps
  },
  ...props
}) => (
  <FeatureBoxCarousel {...props} {...rootProps}>
    {items?.length && items.map(({ id, ...itemProps }) =>
      <FeatureBoxItem key={id} {...itemProps} />
    )}
  </FeatureBoxCarousel>
);

export default ContentfulFeatureBoxCarousel;
