import React from "react";
import NewsletterSignup from "./NewsletterSignup";

const ContentfulNewsletterSignup = ({
  contentfulData: {
    heading,
    body,
    ...rootProps
  },
  ...props
}) => (
  <NewsletterSignup heading={heading} body={body} {...props} {...rootProps} />
);

export default ContentfulNewsletterSignup;
