import React from 'react';
import { css } from "@emotion/react";

import LogoStrip from "./LogoStrip";
import ContentfulRichText from "./ContentfulRichText";

const ContentfulLogoStrip = ({
  contentfulData: {
    headingStrapline,
    heading,
    content,
    ctaLabel,
    ctaLink,
    logos,
  },
  ...props
}) => (
  <LogoStrip
    {...props}
    ctaLabel={ctaLabel}
    ctaLink={ctaLink}
    strapline={headingStrapline}
    heading={heading}
    content={content &&
      <ContentfulRichText
        json={content.raw}
        css={css`
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        `}
      />
    }
  >
    {logos.map(({
        id,
        localFile,
        title,
      }) =>
      <img
        key={id}
        src={localFile?.publicURL}
        alt={title}
      />
    )}
  </LogoStrip>
);

export default ContentfulLogoStrip;
