import React from "react";
import { css } from "@emotion/react";
import { BREAKPOINTS } from "../css-vars";
import { mediaQuery, pixelsToRem } from "../utils/css-helpers";

const TwoColumns = ({
  left,
  right,
  topColumnOnMobile,
}) => {
  // WHY: IE11 doesn’t support grid-gap, so we have to create an extra grid track for the spacing and position
  //      child elements with grid-column instead
  const columnTemplate = `1fr 7% 1fr`;

  return (
    <div css={css`
      display: -ms-grid;
      -ms-grid-columns: 1fr;
      -ms-grid-rows: auto auto;

      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;

      ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
        -ms-grid-columns: ${columnTemplate};
        -ms-grid-rows: auto;

        grid-template-columns: ${columnTemplate};
        grid-template-rows: auto;
      }
    `}>

      <div css={css`
        ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
          grid-row: ${topColumnOnMobile === `right` ? `1` : `2`};
          -ms-grid-row: ${topColumnOnMobile === `right` ? `1` : `2`};
          content: '${topColumnOnMobile}';

          margin-${topColumnOnMobile === `left` ? `top` : `bottom`}: ${pixelsToRem(40)};
        }

        ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
          -ms-grid-column: 1;
          grid-column: 1;
        }
      `}>
        {left}
      </div>

      <div css={css`
        ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
          grid-row: ${topColumnOnMobile === `left` ? `1` : `2`};
          -ms-grid-row: ${topColumnOnMobile === `left` ? `1` : `2`};
        }

        ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
          -ms-grid-column: 3;
          grid-column: 3;
        }
      `}>
        {right}
      </div>

    </div>
  )
};

export default TwoColumns;
