import React from "react";
import { css } from "@emotion/react";
import { GatsbyImage } from "gatsby-plugin-image";

const PhotoOverlay = ({
  fluid,
  altText="",
  ...props
}) => {
//console.log(props)
  return (
    <GatsbyImage
      image={fluid}
      {...props}
      css={css`
        display: block;
        // Gatsby puts position:relative as an inline style,
        // there's no other way to win over it...
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
          // at the time of writing there is no cleaner way to
          // tell Gatsby to use "contain" instead of "cover"...
          object-fit: contain !important;
        }
      `}
      alt={altText}
      role="presentation" />
  );
};

export default PhotoOverlay;
