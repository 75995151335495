import React from "react";
import TimelineCarousel from "./TimelineCarousel";

const ContentfulTimelineCarousel = ({
  contentfulData: {
    heading,
    items
  },
  ...props
}) => (
  <TimelineCarousel heading={heading} items={items} {...props} />
);

export default ContentfulTimelineCarousel;
