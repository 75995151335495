import React from 'react';
import ContentfulRichText from "./ContentfulRichText";
import SmallCopyGrid from "./SmallCopyGrid";
import SmallCopy from "./SmallCopy";

const ContentfulSmallCopyGrid = ({
  contentfulData: {
    headingStrapline,
    heading,
    content,
    buttonText,
    buttonLink,
    smallCopyBlocks,
  },
  ...props
}) => (
  <SmallCopyGrid
    {...props}
    strapline={headingStrapline}
    heading={heading}
    content={content && <ContentfulRichText json={content}/>}
    buttonText={buttonText}
    buttonLink={buttonLink}
  >
    {smallCopyBlocks && smallCopyBlocks.map(({
        id,
        logo,
        content,
        buttonText,
        buttonLink,
      }) => (
        <SmallCopy
          key={id}
          imgUrl={logo?.localFile?.publicURL}
          content={content && <ContentfulRichText json={content}/>}
          buttonText={buttonText}
          buttonLink={buttonLink}
        />)
    )}
  </SmallCopyGrid>
);


export default ContentfulSmallCopyGrid;
