import React from "react";
import { graphql } from "gatsby";
import ContentfulGenericPage from "../components/ContentfulGenericPage";

export const query = graphql`
  query($contentful_id: String!) {
    contentfulGenericPage(contentful_id: {eq: $contentful_id}) {
      ...GenericPage
    }
  }
`;

const GenericPageTemplate = ({ data }) => {
return <ContentfulGenericPage data={data} />;}


export default GenericPageTemplate;
