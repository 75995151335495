import React from "react";
import { css } from "@emotion/react";
import Layout from "./Layout";
import SEO from "./Seo";
import Hero from "./Hero";
import {Helmet} from "react-helmet"
import PhotoWithFlourish from "./PhotoWithFlourish/PhotoWithFlourish";
import ContentfulPageBlocks from "./ContentfulPageBlocks";
import { extractFlourishColor } from "../utils/contentful";

const ContentfulGenericPage = ({
  data: {
    contentfulGenericPage: {
      brand,
      seoTitle,
      jsonLdMarkup,
      seoMetaDescription,
      heroTitle,
      heroPhoto,
      pageBlocks,
      alternativeBackgrounds,
      nofollow,
      ...therest
    }
  }
}) => {
//console.log(therest)
  if (seoMetaDescription) {
    // LongText fields in Contentful have a weird structure: if empty they return null,
    // if filled they return an object with the actual content in a homonym child...
    // https://github.com/gatsbyjs/gatsby/issues/3205
    seoMetaDescription = seoMetaDescription.seoMetaDescription;
  }

  // heroPhoto can be null, so we have to deconstruct separately
  const {
    photo,
    overlayPhoto,
    flourishColor,
    altText
  } = heroPhoto || {};

  let heroPhotoComponent = null;
  if (photo) {
    heroPhotoComponent = (
      <PhotoWithFlourish
        css={css`margin: 0 auto`}
        photoFluid={photo?.gatsbyImage}
        overlayFluid={overlayPhoto?.gatsbyImage}
        loading="eager"
        flourishPosition="right"
        flourishColor={flourishColor && extractFlourishColor(flourishColor)}
        title={seoTitle}
        altText={altText}
      />
    );
  }

  return (
    <Layout
      brand={brand}
      nofollow={nofollow}
      hero={
        <Hero
          title={heroTitle}
          photo={heroPhotoComponent}
        />
      }
    >

      <SEO
        title={seoTitle}
        description={seoMetaDescription}
      />

      {jsonLdMarkup ? <Helmet><script type="application/ld+json">{JSON.stringify(jsonLdMarkup)}</script></Helmet> : null}

      <ContentfulPageBlocks
        blocks={pageBlocks}
        backgrounds={alternativeBackgrounds}
      />

    </Layout>
  );
};

export default ContentfulGenericPage;
