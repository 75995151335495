import React from "react";
import FullWidthPhoto from "./FullWidthPhoto";
import ContentfulRichText from "./ContentfulRichText";
import { extractFlourishColor } from "../utils/contentful";

const ContentfulFullWidthPhoto = ({
  contentfulData: {
    id,
    heading,
    bodyContent = {},
    photo: {
      photo,
      overlayPhoto,
      flourishColor,
      altText
    },
  },
  ...props
}) => {

  //const { raw } = bodyContent;

  return (
    <FullWidthPhoto
      {...props}
      title={heading}
      copy={bodyContent && <ContentfulRichText json={bodyContent} />}
      photoFluid={photo?.gatsbyImage}
      overlayFluid={overlayPhoto?.gatsbyImage}
      flourishColor={flourishColor && extractFlourishColor(flourishColor)}
      altText={altText}
    />
  );
};

export default ContentfulFullWidthPhoto;
