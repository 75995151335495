import React from "react";
import CopyWithPhoto from "./CopyWithPhoto";
import ContentfulRichText from "./ContentfulRichText";
import { extractFlourishColor } from "../utils/contentful";

const ContentfulCopyWithPhoto = ({
  contentfulData: {
    heading,
    headingStrapline,
    bodyContent = {},
    ctaLabel,
    ctaLink,
    fullWidth,
    photoPosition,
    photo: {
      photo,
      overlayPhoto,
      flourishColor,
      altText
    },
    video,
    videoThumbnail,
    newsletterSignup,
  },
  ...props
}) => {

  //const { raw } = bodyContent;

  return (
    <CopyWithPhoto
      {...props}
      strapline={headingStrapline}
      title={heading}
      cta={ctaLabel}
      ctaHref={ctaLink}
      copy={bodyContent && <ContentfulRichText json={bodyContent} />}
      photoPosition={photoPosition ? `left` : `right`}
      photoFluid={photo?.gatsbyImage}
      overlayFluid={overlayPhoto?.gatsbyImage}
      flourishColor={flourishColor && extractFlourishColor(flourishColor)}
      altText={altText}
      video={video}
      videoThumbnail={videoThumbnail}
      newsletterSignup={newsletterSignup}
      fullWidth={fullWidth}
    />
  );
};

export default ContentfulCopyWithPhoto;
