import React, { useEffect } from "react";
import Glide from "@glidejs/glide";
import { css } from "@emotion/react";
import { BREAKPOINTS, COLORS } from "../../css-vars";
import { mediaQuery, pixelsToRem } from "../../utils/css-helpers";
import Heading from "../Heading";

const styles = {
  0: css`
    margin: ${pixelsToRem(25)} auto;
    max-width: ${pixelsToRem(1024)};
    width: 100%;

    ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
      margin-bottom: ${pixelsToRem(50)};
      margin-top: ${pixelsToRem(50)};
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      margin-bottom: ${pixelsToRem(100)};
      margin-top: ${pixelsToRem(100)};

      .glide {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 0 auto;
        max-width: calc(100vw - ${pixelsToRem(50)});
        
        &__track {
          width: 100% !important;
        }
      }
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
      margin-bottom: ${pixelsToRem(140)};
      margin-top: ${pixelsToRem(140)};
    }


  // Feature Box Item Styles //
    .fbi__btn-wrap {
      left: ${pixelsToRem(25)};

      ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
        bottom: ${pixelsToRem(32)};
      }
    }
    .gatsby-image-wrapper {
      max-width: ${pixelsToRem(400)};
      width: 100%;

      div {
        align-items: center;
        display: flex !important;
        justify-content: center;
      }
    }
    .glide {
      &__slide {
        height: auto !important;

        > a {
          display: block;
          height: 100%;
          padding: 2px;
        }
      }
    }

    figcaption {
      background-color: #fff;
      flex: 1;
      width: 100%;

      > span:first-of-type {
        display: none;
      }

      ._heading-override, p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      ._heading-override {
        -webkit-line-clamp: 2;
      }
      p {
        -webkit-line-clamp: 3;
      }
      
    }
    figure {
      align-items: stretch;
      box-shadow: 0px 0px 2px 0  rgba(0,0,0, .5);
      display: flex;
      flex-flow: column;
      justify-content: center;
      margin: 0 auto;
      max-width: min(100vw - ${pixelsToRem(25)}, ${pixelsToRem(400)}, 100%);
      width: ${pixelsToRem(400)};
    }
    img {
      width: 100%;
    }


  // node_modules/@glidejs/glide/dist/css/glide.core.min.css
    .glide{position:relative;width:100%;box-sizing:border-box}.glide *{box-sizing:inherit}.glide__track{overflow:hidden}.glide__slides{position:relative;width:100%;list-style:none;backface-visibility:hidden;transform-style:preserve-3d;touch-action:pan-Y;overflow:hidden;margin:0;padding:0;white-space:nowrap;display:flex;flex-wrap:nowrap;will-change:transform}.glide__slides--dragging{user-select:none}.glide__slide{width:100%;height:100%;flex-shrink:0;white-space:normal;user-select:none;-webkit-touch-callout:none;-webkit-tap-highlight-color:transparent}.glide__slide a{user-select:none;-webkit-user-drag:none;-moz-user-select:none;-ms-user-select:none}.glide__arrows{-webkit-touch-callout:none;user-select:none}.glide__bullets{-webkit-touch-callout:none;user-select:none}.glide--rtl{direction:rtl}
  `,
  button: css`
    align-items: center;
    appearance: none;
    background-color: ${COLORS.secondaryA11y};
    border: 0;
    border-radius: 0;
    color: #fff;
    cursor: pointer;
    font-size: ${pixelsToRem(16)};
    display: flex;
    justify-content: center;
    margin: 0;
    min-height: ${pixelsToRem(44)};
    padding-bottom: ${pixelsToRem(22)};
    padding-top: ${pixelsToRem(22)};
    position: relative;
    height: ${pixelsToRem(44)};
    min-width: ${pixelsToRem(44)};
    transition: .25s ease-in-out background-color;
      
    &::after, &::before {
      background-color: ${COLORS.secondaryA11y};
      content: "";
      display: block;
      height: 100%;
      left: 100%;
      position: absolute;
      transition: .25s ease-in-out all;
      width: 0;
    }
    &:hover {
      background-color: ${COLORS.secondaryA11yDark};
    }

    svg {
      transition: .25s ease-in-out transform;
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
      font-size: ${pixelsToRem(18)};
    }
  `,
  buttonWrap: css`
    align-items: center;
    display: flex;
    justify-content: center;

    &.next {
      button:hover {
        &::before {
          background-color: ${COLORS.secondaryA11yDark};
          width: ${pixelsToRem(10)};
        }
        svg {
          transform: translateX(${pixelsToRem(5)});
        }
      }
    }
    &.prev {
      transform: rotate(180deg);

      button:hover {
        &::after {
          background-color: ${COLORS.secondaryA11yDark};
          width: ${pixelsToRem(10)};
        }
        svg {
          transform: translateX(${pixelsToRem(5)});
        }
      }
    }

    a {
      padding-left: ${pixelsToRem(36)};
      padding-right: ${pixelsToRem(36)};

      &:hover {
        &::after {
          background-color: ${COLORS.secondaryA11yDark};
          width: ${pixelsToRem(10)};
        }
        svg {
          transform: translateX(${pixelsToRem(5)});
        }
      }
    }
  `,
  footer: css`
    margin: ${pixelsToRem(40)} 0 0;

    svg {
      margin-left: ${pixelsToRem(36)};
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      order: 3;
    }
  `,
  header: css`
    flex: 1 1 auto;
    margin: 0 0 ${pixelsToRem(28)};
    max-width: 400px;
    padding: 0 ${pixelsToRem(25)};
    text-align: left;

    ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
      max-width: none;
      padding: 0 ${pixelsToRem(50)};
    }

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      flex: 1 1 1px;
      margin-top: ${pixelsToRem(28)};
      padding: 0 0 0 ${pixelsToRem(2)};
    }
  `,
  section: css`
    align-items: center;
    display: flex;
    gap: ${pixelsToRem(40)};
    justify-content: center;
    margin: ${pixelsToRem(16)} 0 0;

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      margin: ${pixelsToRem(28)} 0;
      padding: 0 ${pixelsToRem(2)} 0 0;
    }
  `,
  track: css`
    position: relative;

    /*
    ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
      &:after {
        backdrop-filter: blur(${pixelsToRem(2)});
        background: rgba(255,255,255, 0.8);
        bottom: 0;
        content: "";
        display: block;
        filter: blur(${pixelsToRem(8)});
        height: 100%;
        position: absolute;
        right: ${pixelsToRem(-16)};
        top: 0;
        width: ${pixelsToRem(64)};
      }
    }
    */

    ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
      order: 2;
      padding-left: 0;
      padding-right: 0;
    }
  `,
  ul: css`
    padding-bottom: ${pixelsToRem(12)} !important;
  `,

  Heading: css`
    margin: 0;
  `,
};

const FeatureBoxCarousel = ({
  children,
  ctaLink = null,
  ctaText = null,
  heading,
}) => {
  useEffect(() => {
    const slider = new Glide('.glide', {
      breakpoints: {
        767: {
          gap: 25,
          perView: 1,
        },
        864: {
          gap: 25,
          perView: 1.5,
        },
        960: {
          gap: 25,
          perView: 2,
        },
      },
      focusAt: 0,
      gap: 25,
      perView: 3,
      type: 'slider',
    }).mount();

    return () => slider.destroy();
  }, []);

  return (
    <div className="fbc__root" css={styles[0]}>
      <div className="glide">
        <header css={styles.header}>
          <Heading css={styles.Heading} tag={"h2"}>{heading}</Heading>
        </header>

        <div className="glide__track" css={styles.track} data-glide-el="track">
          <ul className="glide__slides" css={styles.ul}>
            {children}
          </ul>
        </div>

        <section className="glide__arrows" css={styles.section} data-glide-el="controls">
          <div className="prev glide__arrow glide__arrow--prev" data-glide-dir="<" css={styles.buttonWrap}>
            <button css={styles.button}>
              <svg viewBox="0 0 29 18" xmlns="http://www.w3.org/2000/svg" width="20"><path d="M1 9h26m-6.5 5.5 7-5.5-7-5.5" stroke="#FFF" strokeWidth="1.5" fill="none" fillRule="evenodd"></path></svg>
            </button>
          </div>

          <div className="next glide__arrow glide__arrow--next" data-glide-dir=">" css={styles.buttonWrap}>
            <button css={styles.button}>
              <svg viewBox="0 0 29 18" xmlns="http://www.w3.org/2000/svg" width="20"><path d="M1 9h26m-6.5 5.5 7-5.5-7-5.5" stroke="#FFF" strokeWidth="1.5" fill="none" fillRule="evenodd"></path></svg>
            </button>
          </div>
        </section>

        {ctaLink && ctaText &&
          <footer css={styles.footer}>
            <div css={styles.buttonWrap}>
              <a className="next" css={styles.button} href={ctaLink}>
                <div>
                  <span>{ctaText}</span>
                </div>
                <svg viewBox="0 0 29 18" xmlns="http://www.w3.org/2000/svg" width="20"><path d="M1 9h26m-6.5 5.5 7-5.5-7-5.5" stroke="#FFF" strokeWidth="1.5" fill="none" fillRule="evenodd"></path></svg>
              </a>
            </div>
          </footer>
        }
      </div>
    </div>
  )
};

export default FeatureBoxCarousel;
