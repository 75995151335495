import React from "react";
import { FeatureBoxGrid, FeatureBoxItem } from "./FeatureBox";

const ContentfulFeatureBoxGrid = ({
  contentfulData: {
    items
  },
  ...props
}) => (
  <FeatureBoxGrid {...props}>
    {items?.length && items.map(({ id, ...itemProps }) =>
      <FeatureBoxItem key={id} {...itemProps} />
    )}
  </FeatureBoxGrid>
);

export default ContentfulFeatureBoxGrid;
