import React from "react";
import { css } from "@emotion/react";
import { BREAKPOINTS } from "../../css-vars";
import { mediaQuery, pixelsToRem } from "../../utils/css-helpers";
import AngleSvg from "./assets/angle.svg";
import ThinAngleSvg from "./assets/thin-angle.svg";
import DiagonalSvg from "./assets/diagonal.svg";
import ZigZagSvg from "./assets/zigzag.svg";
import TriangleSvg from "./assets/triangle.svg";
import MobileSvg from "./assets/mobile.svg";

const MidBackground = ({
  topStyle = `angle`,
  bottomStyle = `angle`,
  topUnderlap = true,
  bottomUnderlap = true,
  children,
  ...props
}) => {

  // NOTE: both offsets and heights in this map are pixels
  const styles = {
    "angle" : {
      svg: AngleSvg,
      topOffset: 30,
      bottomOffset: -250,
      height: 716,
    },
    "thin-angle" : {
      svg: ThinAngleSvg,
      topOffset: 65,
      bottomOffset: 65,
      height: 322,
    },
    "thin-angle-right" : {
      svg: ThinAngleSvg,
      flipHorizontally: true,
      topOffset: 65,
      bottomOffset: 65,
      height: 322,
    },
    "diagonal-left" : {
      svg: DiagonalSvg,
      topOffset: -65,
      bottomOffset: 65,
      height: 463,
    },
    "diagonal-right" : {
      svg: DiagonalSvg,
      flipHorizontally: true,
      topOffset: 0,
      bottomOffset: 0,
      height: 463,
    },
    "zigzag" : {
      svg: ZigZagSvg,
      topOffset: -45,
      bottomOffset: 0,
      height: 433,
    },
    "triangle" : {
      svg: TriangleSvg,
      topOffset: 65,
      bottomOffset: 65,
      height: 326,
    },
  };

  const {
    svg: topSvg,
    flipHorizontally: topFlipHorizontally,
    topOffset,
    height: topHeight,
  } = styles[topStyle];
  const {
    svg: bottomSvg,
    flipHorizontally: bottomFlipHorizontally,
    bottomOffset,
    height: bottomHeight
  } = styles[bottomStyle];

  // Style and offset are fixed on mobile
  const mobileHeight = 126;
  const mobileOffset = 30;
  const mobileExtraMargin = 30;

  return (
    <div {...props} css={css`
      position: relative;
      // Never underlap on mobile
      margin: ${pixelsToRem(mobileHeight + mobileOffset + mobileExtraMargin)} 0;
      ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
        // With underlap enabled the border of the background slips under the previous/next section;
        // with underlap disabled the margin is increased accordingly to avoid so
        margin-top: ${topUnderlap ? 0 : pixelsToRem(topHeight + topOffset)};
        margin-bottom: ${bottomUnderlap ? 0 : pixelsToRem(bottomHeight + bottomOffset)};
      }
    `}>

      <div css={css`
        // This div element draws the background
        position: absolute;
        z-index: -1;
        width: 100%;
        background: #dcf2ff;
        left: 0;

        // Expand the background so top and bottom borders can be positioned around
        // the contents of the section;
        // background height = contents height + top border height + tob border offset + bottom border height + bottom border offset
        top: ${pixelsToRem((mobileHeight + mobileOffset) * -1)};
        height: calc(100% + ${pixelsToRem((mobileHeight + mobileOffset) * 2)});

        ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
          top: ${pixelsToRem((topHeight + topOffset) * -1)};
          height: calc(100% + ${pixelsToRem(topHeight + topOffset + bottomHeight + bottomOffset)});
        }

        ::before {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;

          height: ${pixelsToRem(mobileHeight)};
          background: url(${MobileSvg}) no-repeat;

          ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
            height: ${pixelsToRem(topHeight)};
            background: url(${topSvg}) no-repeat;
            transform: ${topFlipHorizontally && `scaleX(-1)`};
          }
        }

        ::after {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          // BUG: with "bottom: 0" and zoom 110% some browsers place the element 1 pixel
          // above the expected position, revealing the underlying background and resulting
          // in a ugly horizontal line that crosses the entire viewport (tested with Chrome 80
          // on macOS Catalina on a retina display)
          // FIX: pushing the background 2 pixels down solves the issue
          bottom: ${pixelsToRem(-2)};

          height: ${pixelsToRem(mobileHeight)};
          background: url(${MobileSvg}) no-repeat;
          transform: scaleY(-1);

          ${mediaQuery({ minWidth: BREAKPOINTS.small })} {
            height: ${pixelsToRem(bottomHeight)};
            background: url(${bottomSvg}) no-repeat;
            transform: scaleY(-1) ${bottomFlipHorizontally && `scaleX(-1)`};
          }
        }
      `}/>

      {children}

    </div>
  )
};

export default MidBackground;
